import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入element-ui的index.css
import 'element-ui/lib/theme-chalk/index.css'
// 按需引入组件
import './plugins/elementUI.js'
import './assets/css/common.css'
import './assets/css/iconfont1.css'
import './assets/css/iconfont.css'
import '@/plugins/axios'
import '@/plugins/echarts'
import '@/plugins/filter'
import '@/plugins/citydata'
import '@/plugins/nProgress'
import '@/plugins/quillEditoe'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

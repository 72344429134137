import Vue from 'vue'
import axios from 'axios'
import Nprogress from 'nprogress'

// 设置基准路径
axios.defaults.baseURL = 'http://managerv2.leevip.com.cn:4000/api/private/v1/'
// 设置axios超时事件
// axios.defaults.timeout = 5000
// 响应拦截器
axios.interceptors.response.use(res => {
  Nprogress.done()
  return res.data
})
// 请求拦截器
axios.interceptors.request.use(config => {
  Nprogress.start()
  config.headers.Authorization = sessionStorage.getItem('token')
  return config
})

Vue.prototype.$axios = axios

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  }, {
    path: '/main',
    name: 'main',
    redirect: '/welcome',
    component: () => import('../views/MainView.vue'),
    children: [
      {
        path: '/users',
        name: 'users',
        component: () => import('@/views/UsersView.vue')
      },
      {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/RolesView.vue')
      },
      {
        path: '/rights',
        name: 'rights',
        component: () => import('@/views/RightsView.vue')
      },
      {
        path: '/goods',
        name: 'goods',
        component: () => import('@/views/GoodsView.vue')
      },
      {
        path: '/params',
        name: 'params',
        component: () => import('@/views/ParamsView.vue')
      },
      {
        path: '/categories',
        name: 'categories',
        component: () => import('@/views/CategoriesView.vue')
      },
      {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/OrdersView.vue')
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/ReportsView.vue')
      },
      {
        path: '/welcome',
        name: 'welcome',
        component: () => import('@/views/WelComeView.vue')
      },
      {
        path: '/add',
        name: 'add',
        component: () => import('@/views/AddGoodsView.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

// 路由守卫 钩子函数beforeEach((to,from,next)=>{})
/**
 *  to 到哪里去
 *  from 从哪里来
 *  next 是否同意过去
 */
router.beforeEach((to, from, next) => {
  // 如果要去/login，可以直接放行
  if (to.path === '/login') return next()

  // 判断当前是否登录
  if (!sessionStorage.getItem('token')) return next('/login')

  // 否则，直接放行
  next()
})

export default router
